<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Historial de becas de {{ carnet }}</v-toolbar-title
               >
            </v-toolbar>

            <!-- DATATABLE becas -->
            <v-data-table
               :headers="headers"
               :items="becas"
               :loading="loadingBecas"
               loading-text="Listando becas, por favor espere..."
               no-data-text="No se encontraron becas anteriores."
               hide-default-footer
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               class="elevation-1"
            >
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'BecasHistorial',
   directives: {
      mask
   },
   props: {
      carnet: {
         type: String,
         default: '2013-DP-102'
      }
   },
   data: () => ({
      // Variables
      headers: [
         { text: 'Ciclo', value: 'periodoLectivo', class: 'titulo' },
         { text: 'Tipo', value: 'tipoBeca', class: 'titulo' },
         { text: 'Categoria', value: 'categoria', class: 'titulo' },
         { text: 'Descripción', value: 'tipoBecaDescripcion', class: 'titulo' }
      ],
      becas: [],
      loadingBecas: false
   }),
   computed: {
      ...authComputed
   },
   watch: {
      carnet() {
         this.listarBecas()
      }
   },
   created() {},
   methods: {
      // Popular el datatable
      listarBecas() {
         let me = this
         me.becas = []
         me.loadingBecas = true
         // Obteniendo becas
         if (me.carnet != '') {
            axios
               .get('api/Beca?Carnet=' + me.carnet + '&OrderByAsc=true')
               .then(function (response) {
                  me.becas = response.data
                  me.loadingBecas = false
               })
               .catch(function (error) {
                  console.log(error)
                  me.loadingBecas = false
               })
         } else {
            me.loadingBecas = false
         }
      },
      // Si no cargaron bien las becas en el datatable se puede resetear
      reset() {
         this.listarBecas()
      }
   }
}
</script>
